import { useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { QueryKeyConstants } from "constants/query.key.constants";

import { GlobalContext } from "context/globalContext";

// Axios Request
import { request } from "api";

// fetch user cart
export const useUserCartQuery = () => {
  const { isAuthenticated } = useAuth0();
  const { setPageLoading } = useContext(GlobalContext);

  return useQuery(
    [QueryKeyConstants.fetchUserCartKey],
    () => request({ url: "/cart", method: "GET" }),
    {
      staleTime: 300000,
      enabled: isAuthenticated,
      onSuccess: () => {
        setPageLoading(false);
      },
    }
  );
};

// add product to cart
export const useAddProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (reqBody) =>
      request({
        url: `/cart/add`,
        method: "POST",
        data: reqBody,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeyConstants.fetchUserCartKey]);
      },
    }
  );
};

// remove product from cart
export const useRemoveProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (reqBody) =>
      request({
        url: `/cart`,
        method: "DELETE",
        data: reqBody,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeyConstants.fetchUserCartKey]);
      },
    }
  );
};
