import { useContext } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { GlobalContext } from "context/globalContext";

const PageLoader = () => {
  const { pageLoading } = useContext(GlobalContext);
  if (!pageLoading) {
    return null;
  }
  return (
    <>
      <Box />
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "44%",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    </>
  );
};

export default PageLoader;
