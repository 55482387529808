// QueryKeyConstants -> Represents all the keys for different queries (GET Requests) made across the application
export const QueryKeyConstants = {
  // vendor data key
  fetchAllVendorsKey: "fetch-all-vendors",
  fetchVendorDetailKey: "fetch-vendor-detail",
  fetchVendorProductsKey: "fetch-vendor-products",
  fetchAllProductsKey: "fetch-all-products",

  // user cart data key
  fetchUserCartKey: "fetch-user-cart",

  // account data key
  fetchAccountKey: "fetch-account",
  fetchAccountCodeKey: "fetch-account-code",

  // order data key
  fetchUserOrderKey: "fetch-user-order",
  fetchOrderDetailKey: "fetch-order-detail",

  // user data key
  fetchUserProfileKey: "fetch-user-profile",

  // event data key
  fetchEventDataKey: "fetch-event-data"
};
