import { Viewer } from "@react-pdf-viewer/core";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Worker
import { Worker } from "@react-pdf-viewer/core"; // install this library

import Box from "@mui/material/Box";

import { FullScreenModal } from "components/Molecules/FullScreenModal";

const PdfViewer = ({ pdfUrl, handleClose }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Box>
      <FullScreenModal
        hideConfirmation
        open={!!pdfUrl}
        handleClose={handleClose}
      >
        {pdfUrl && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">
            <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        )}
      </FullScreenModal>
    </Box>
  );
};

export default PdfViewer;
