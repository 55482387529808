import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "react-query";

import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Badge from "@mui/material/Badge";

import { Button } from "components/Atoms/Button";
import Drawer from "components/Drawer";
import AppLogo from "components/AppLogo";
import { drawerLinks } from "components/Drawer";

import getSingletonSocket from "singleton/socket";
import { EventConstants } from "constants/events.constants";
import { QueryKeyConstants } from "constants/query.key.constants";

import PdfViewer from "components/PdfViewer";

import { useUserCartQuery } from "hooks/cartData";

function AppHeader() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { logout, isAuthenticated, loginWithRedirect, user } = useAuth0();

  const socket = getSingletonSocket();
  const queryClient = useQueryClient();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openPdf, setOpenPdf] = useState("");

  const { data: userCart } = useUserCartQuery();

  const toggleDrawer = (open) => {
    if (open) {
      setDrawerOpen(open);
    } else {
      setDrawerOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (user) {
      socket.on(EventConstants.couponBought, (eventData) => {
        if (eventData.id === user.sub) {
          queryClient.invalidateQueries([
            QueryKeyConstants.fetchUserProfileKey,
          ]);
          queryClient.invalidateQueries([QueryKeyConstants.fetchUserOrderKey]);
          queryClient.invalidateQueries([QueryKeyConstants.fetchAccountKey]);
        }
      });

      socket.on(EventConstants.sharedAccountCreated, (eventData) => {
        if (eventData.consumer === user.sub) {
          queryClient.invalidateQueries([QueryKeyConstants.fetchAccountKey]);
        }
      });

      socket.on(EventConstants.sharedAccountDeleted, (eventData) => {
        if (eventData.consumer === user.sub) {
          queryClient.invalidateQueries([QueryKeyConstants.fetchAccountKey]);
        }
      });

      socket.on(EventConstants.sharedAccountUsed, (eventData) => {
        if (eventData.byProvider) {
          queryClient.invalidateQueries([QueryKeyConstants.fetchAccountKey]);
        } else {
          queryClient.invalidateQueries([QueryKeyConstants.fetchUserOrderKey]);
          queryClient.invalidateQueries([QueryKeyConstants.fetchAccountKey]);
          queryClient.invalidateQueries([
            QueryKeyConstants.fetchUserProfileKey,
          ]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <AppBar>
      <Container maxWidth="xl">
        <PdfViewer pdfUrl={openPdf} handleClose={() => setOpenPdf("")} />
        <Toolbar disableGutters>
          <AppLogo style={{ display: { xs: "none", md: "flex" } }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Taste of India
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => toggleDrawer()}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Drawer open={drawerOpen} toggleDrawer={toggleDrawer} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Taste of India
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {drawerLinks.map((link) => (
              <Button
                key={link.label}
                onClick={() => {
                  if (link.to.startsWith("/")) {
                    navigate(link.to);
                  } else {
                    setOpenPdf(link.to);
                  }
                }}
                sx={{ my: 2, color: "white", textTransform: "none" }}
                variant="text"
              >
                {link.label}
              </Button>
            ))}
            {isAuthenticated && (
              <Button
                onClick={() => {
                  logout({ returnTo: window.location.origin });
                }}
                sx={{ my: 2, color: "white", textTransform: "none" }}
                variant="text"
              >
                Logout
              </Button>
            )}
            {!isAuthenticated && (
              <Button
                onClick={() => {
                  loginWithRedirect({
                    appState: { returnTo: "/" },
                  });
                }}
                sx={{ my: 2, color: "white", textTransform: "none" }}
                variant="text"
              >
                Login
              </Button>
            )}
          </Box>

          {/* Right hand side menu */}
          <Box sx={{ flexGrow: 0, display: "flex", justifyContent: "end" }}>
            <Tooltip title="Orders">
              <IconButton
                onClick={() => navigate("/transactions")}
                sx={{
                  p: 0,
                  mr: theme.spacing(3),
                  color: theme.palette.common.white,
                }}
              >
                <ReceiptIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cart">
              <IconButton
                onClick={() => navigate("/cart")}
                sx={{
                  p: 0,
                  color: theme.palette.common.white,
                }}
              >
                <Badge badgeContent={userCart?.products.length} color="error">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppHeader;
