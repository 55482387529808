import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";


import MainLayout from "layouts/MainLayout";

const ProtectedElement = ({ component: Component, hideContainer, ...rest }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      setTimeout(() => navigate("/", { replace: true }), 0);
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: pathname },
    });
    return null;
  }

  return (
    <MainLayout hideContainer={hideContainer}>
      <Component {...rest} />
    </MainLayout>
  );
};

export default ProtectedElement;
