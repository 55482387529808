// EventConstants -> Represents all the keys for socket events
export const EventConstants = {
  // order update event
  orderUpdated: "order_updated",

  // coupon bought
  couponBought: "coupon_bought",

  // shared account added
  sharedAccountCreated: "share_account_created",

  // shared account deleted
  sharedAccountDeleted: "share_account_deleted",

  // shared account used
  sharedAccountUsed: "transactions",
};
