import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { PublicElement, ProtectedElement } from "components/Element";

const HomePage = lazy(() => import("pages/HomePage"));
const Vendors = lazy(() => import("pages/Vendors"));
const Profile = lazy(() => import("pages/Profile"));
const VendorProducts = lazy(() => import("pages/VendorProducts"));
const Cart = lazy(() => import("pages/Cart"));
const ShareAccount = lazy(() => import("pages/ShareAccount"));
const Transactions = lazy(() => import("pages/Transactions"));
const BuyCoupon = lazy(() => import("pages/BuyCoupon"));
const Products = lazy(() => import("pages/Products"));
const FloorPlan = lazy(() => import("pages/FloorPlan"));

const FourOhFour = lazy(() => import("pages/Error/FourOhFour"));

const Router = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<PublicElement component={HomePage} />} />
      <Route
        path="/vendors/:vendorId"
        element={<PublicElement component={VendorProducts} />}
      />
      <Route path="/vendors" element={<PublicElement component={Vendors} />} />
      <Route path="/products" element={<PublicElement component={Products} />} />
      <Route path="/floor-plan" element={<PublicElement component={FloorPlan} />} />

      {/* Protected Routes */}
      <Route
        path="/profile"
        element={<ProtectedElement component={Profile} />}
      />
      <Route path="/cart" element={<ProtectedElement component={Cart} />} />
      <Route
        path="/share-account"
        element={<ProtectedElement component={ShareAccount} hideContainer />}
      />
      <Route
        path="/transactions"
        element={<ProtectedElement component={Transactions} hideContainer />}
      />
      <Route
        path="/buy-coupon"
        element={<ProtectedElement component={BuyCoupon} />}
      />

      {/* 404 page if none of the routes match */}
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  );
};

export default Router;
