import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArticleIcon from "@mui/icons-material/Article";
import DescriptionIcon from "@mui/icons-material/Description";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";

import CoPresentIcon from "@mui/icons-material/CoPresent";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";

import AppLogo from "components/AppLogo";

import PdfViewer from "components/PdfViewer";

export const drawerLinks = [
  { label: "Share Account", icon: <CoPresentIcon />, to: "/share-account" },
  { label: "Orders", icon: <ReceiptIcon />, to: "/transactions" },
  { label: "Profile", icon: <AccountBoxIcon />, to: "/profile" },
  { label: "Floor Plan", icon: <ShapeLineIcon />, to: "/floor-plan" },
  {
    label: "Program & Booths",
    icon: <ArticleIcon />,
    to: "https://toihr.com/wp-content/uploads/2023/04/toi2023_8pager.pdf",
  },
  {
    label: "Terms and Conditions",
    icon: <DescriptionIcon />,
    to: "https://toihr.com/wp-content/uploads/2023/04/terms.pdf",
  },
];

const iOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export default function Drawer({ open, toggleDrawer }) {
  const navigate = useNavigate();
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();

  const [openPdf, setOpenPdf] = useState("");

  return (
    <SwipeableDrawer
      open={open}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <PdfViewer pdfUrl={openPdf} handleClose={() => setOpenPdf("")} />
      <Box sx={{ width: 250 }} role="presentation">
        <Box
          sx={{
            backgroundColor: "primary.main",
            height: "10rem",
            display: "flex",
            padding: "1rem",
            alignItems: "center",
          }}
        >
          <AppLogo />
          <Typography variant="h5" sx={{ color: "#ffffff", fontWeight: 700 }}>
            Taste of India
          </Typography>
        </Box>
        <Divider />
        <List>
          {drawerLinks.map((link) => (
            <ListItem
              key={link.label}
              disablePadding
              onClick={() => {
                toggleDrawer(false);
                if (link.to.startsWith("/")) {
                  setTimeout(() => navigate(link.to), 200);
                } else {
                  setOpenPdf(link.to);
                }
              }}
            >
              <ListItemButton>
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText primary={link.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {isAuthenticated && (
            <ListItem
              disablePadding
              onClick={() => {
                toggleDrawer(false);
                logout({ returnTo: window.location.origin });
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          )}
          {!isAuthenticated && (
            <ListItem
              disablePadding
              onClick={() => {
                toggleDrawer(false);
                loginWithRedirect({
                  appState: { returnTo: "/" },
                });
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
        <Box
          sx={{
            position: "fixed",
            bottom: "2rem",
            left: "1rem",
            textAlign: "center",
          }}
        >
          <Typography variant="caption">
            Designed and Supported by <br />
            Hands-on Lab @ Old Dominion University
          </Typography>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}
