import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";

import api from "api";

import { GlobalContext } from "context/globalContext";
import getSingletonSocket from "singleton/socket";

const AxiosInterceptor = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setPageLoading } = useContext(GlobalContext);

  const { isAuthenticated, getIdTokenClaims, isLoading, logout } = useAuth0();

  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    const onConnect = () => {
      console.log("socket connected!");
    };
    const onDisconnect = () => {
      console.log("socket disconnected!");
    };
    const onConnectError = (err) => {
      console.log("The socket could not connect", err);
      console.log("The socket could not connect", err.name);
      console.log("The socket could not connect", err.message);
    };

    let socket = null;

    const getUserToken = async () => {
      try {
        const claims = await getIdTokenClaims();
        const accessToken = claims.__raw;
        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        setIdToken(accessToken);
        socket = getSingletonSocket(accessToken);
        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("connect_error", onConnectError);
      } catch (e) {
        console.log(e, "error");
      }
    };

    if (isAuthenticated && !idToken) {
      getUserToken();
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    const responseInterceptor = api.interceptors.response.use(
      (next) => Promise.resolve(next.data),
      (error) => {
        if (process.env.NODE_ENV === "development") {
          // eslint-disable-next-line no-console
          console.error(error, "axios");
        }
        setPageLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.headers)
          const { status, config, data } = error.response;
          if (status === 401) {
            enqueueSnackbar("Authorization Error");
            logout({ returnTo: "/" });
            return;
          }
          if (config.method.toUpperCase() !== "GET" && data.message) {
            enqueueSnackbar(data.message);
          }
          throw error;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          throw new Error("No Response from server");
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new Error("Axios Config Error");
        }
      }
    );
    return () => {
      api.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || (isAuthenticated && !idToken)) {
    return null;
  }

  return <>{children}</>;
};

export default AxiosInterceptor;
