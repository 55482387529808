import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";

import HomeIcon from "@mui/icons-material/Home";
import StorefrontIcon from "@mui/icons-material/Storefront";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import ReceiptIcon from "@mui/icons-material/Receipt";


const navigations = [
  { label: "Home", icon: <HomeIcon />, to: "/" },
  { label: "Booths", icon: <StorefrontIcon />, to: "/vendors" },
  { label: "Buy", icon: <LunchDiningIcon />, to: "/products" },
  { label: "Coupon", icon: <LocalAtmIcon />, to: "/buy-coupon" },
  { label: "Orders", icon: <ReceiptIcon />, to: "/transactions" },
];

export default function FixedBottomNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(-1);

  useEffect(() => {
    const pathname = location.pathname;
    setValue(-1);
    navigations.forEach((navigation, index) => {
      if (pathname === navigation.to) {
        setValue(index);
      }
    });
  }, [location.pathname]);

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
          const toUrl = navigations[newValue].to;
          navigate(toUrl);
        }}
      >
        {navigations.map((navigation) => (
          <BottomNavigationAction
            label={navigation.label}
            icon={navigation.icon}
            key={navigation.label}
            sx={{
              "& .MuiBottomNavigationAction-label": {
                fontWeight: 600,
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}
