import React, { Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0Provider } from "@auth0/auth0-react";
// import { ReactQueryDevtools } from "react-query/devtools";

import { SuspenseLoader } from "components/Molecules/SuspenseLoader";
import Router from "router";
import configuration from "configuration";

import "assets/App.css";
import AxiosInterceptor from "components/AxiosInterceptor";
import PageLoader from "components/PageLoader";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Create a client
const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const onRedirectCallback = (appState) => {
    const redirectUrl =
      appState && appState.returnTo
        ? appState.returnTo
        : window.location.origin;
    navigate(redirectUrl, { replace: true });
  };

  return (
    <Auth0Provider
      domain={configuration.auth0Domain}
      clientId={configuration.auth0ClientId}
      connection="email"
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <AxiosInterceptor>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={false} position="top-right" /> */}
          {/* <InstallationPrompt /> */}
          <PageLoader />
          <Suspense fallback={<SuspenseLoader />}>
            <Router />
          </Suspense>
        </QueryClientProvider>
      </AxiosInterceptor>
    </Auth0Provider>
  );
}

export default App;
